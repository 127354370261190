.progress-container {
  width: 100%;
  background-color: #f3f3f3;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 70px;
  margin-top: 45px;
}

.progress-bar {
  height: 30px;
  background-color: #4caf50 !important; 
  text-align: center;
  line-height: 30px;
  color: white;
  border-radius: 5px;
  transition: width 0.3s ease-in-out; 
}
