body {
  margin: 0;
  font-family: Arial, sans-serif;
  height: 100%;
}

.col-lg-3.col-sm-6:hover {
  background-image: linear-gradient(115.3deg, rgb(67, 202, 177) 7.4%, rgb(255, 255, 255) 103.1%);
}
.no-style-link {
  text-decoration: none;
  color: inherit;
}
.text-center {
  text-align: center;
}

.custom-label {
  border: 1px solid gray;
  text-align: left;
  width: 100%;
  height: 35px;
  padding-left: 3px;
  padding-top: 7px;
  border-radius: 7px;
  background-color: white;
  display: block;
  margin-top: 10px;
}

button {
  cursor: pointer;
}
.button-17 {
  align-items: center;
  appearance: none;
  background-image: linear-gradient(115.3deg, rgb(67, 202, 177) 7.4%, rgb(255, 255, 255) 103.1%);
  border-radius: 24px;
  border-style: none;
  box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
  box-sizing: border-box;
  color: #3c4043;
  cursor: pointer;
  display: inline-flex;
  fill: currentcolor;
  font-family: "Google Sans",Roboto,Arial,sans-serif;
  font-size: 14px;
  font-weight: 500;
  height: 48px;
  justify-content: center;
  letter-spacing: .25px;
  line-height: normal;
  max-width: 100%;
  overflow: visible;
  padding: 2px 24px;
  position: relative;
  text-align: center;
  text-transform: none;
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: auto;
  will-change: transform,opacity;
  z-index: 0;
}

.button-17:hover {
background-image: #fff;

}


.button-17:active {
  box-shadow: 0 4px 4px 0 rgb(60 64 67 / 30%), 0 8px 12px 6px rgb(60 64 67 / 15%);
  outline: none;
}

.button-17:focus {
  outline: none;
  border: 2px solid #4285f4;
}

.button-17:not(:disabled) {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-17:not(:disabled):hover {
  box-shadow: rgba(60, 64, 67, .3) 0 2px 3px 0, rgba(60, 64, 67, .15) 0 6px 10px 4px;
}

.button-17:not(:disabled):focus {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.button-17:not(:disabled):active {
  box-shadow: rgba(60, 64, 67, .3) 0 4px 4px 0, rgba(60, 64, 67, .15) 0 8px 12px 6px;
}

.button-17:disabled {
  box-shadow: rgba(60, 64, 67, .3) 0 1px 3px 0, rgba(60, 64, 67, .15) 0 4px 8px 3px;
}

.list-container {
  margin-top: 20px;
}

.list-item {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.list-item-icon {
  background-color: #f3f3f3;
  border-radius: 50%;
  padding: 10px;
  margin-right: 20px;
}

.list-item-content {
  display: flex;
  flex-direction: column;
}

h3 {
  margin: 0;
}

p {
  margin: 5px 0;
}

@media (max-width: 768px) {
  .container {
    width: 95%;
    margin-top: 15px;
    padding: 10px;
  }

  .list-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .list-item-icon {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .custom-label {
    font-size: 14px;
    padding-top: 5px;
    padding-left: 5px;
    height: auto;
  }

  button {
    width: 100%;
    margin-top: 10px;
    height: auto;
    padding: 10px;
  }

  input[type="text"] {
    width: 100%;
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .container {
    width: 100%;
    margin-top: 10px;
    padding: 5px;
  }
  .text-center{
    padding: 2% !important;
  }
  .foto1{
    width: 50%;
  }
  .label-1{
    width: 80% !important;
    border-radius: 15px;
  }
  .label-2{
    width: 70% !important;
    border-radius: 15px;
  }
 
  .custom-label {
    font-size: 12px;
    padding-top: 3px;
    padding-left: 3px;
    height: auto;
  }

  button {
    width: 100%;
    margin-top: 10px;
    height: auto;
    padding: 8px;
  }

  input[type="text"] {
    width: 100%;
    margin-top: 10px;
  }
}

.fade-in {
  opacity: 0;
  animation: fadeIn 7s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.slide-in {
  opacity: 0;
  transform: translateX(100%);
  animation: slideIn 7s forwards;
}

@keyframes slideIn {
  to {
    opacity: 1;
    transform: translateX(0);
  }
  
}
