.list-item {
    display: flex;
    align-items: center;
    padding: 3px;
   
    background-color: #fff;
    transition: all 0.3s ease-in-out;
    background-color: "white";
    color:#208000;
    align-items: center;
    width: 80%;
    justify-content: center;

  }
  
  .list-item-icon {
    background-color: #f3f3f3;
    border-radius: 50%;
    padding: 10px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .list-item-content {
    flex: 1;
  }
  
  h3 {
    margin: 0;
    font-size: 1.2em;
  }
  
  p {
    margin: 5px 0;
    font-size: 1em;
  }
  
  
  @media (max-width: 768px) {
    .list-item {
      flex-direction: column;
      align-items: flex-start;
      padding: 20px;
    }
  
    .list-item-icon {
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .list-item-content {
      text-align: left;
    }
  
    h3 {
      font-size: 1.1em;
    }
  
    p {
      font-size: 0.9em;
    }
  }
  
  @media (max-width: 480px) {
    .list-item {
      padding: 15px;
    }
  
    h3 {
      font-size: 1em;
    }
  
    p {
      font-size: 0.8em;
    }
  }
  