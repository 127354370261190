.container {
  width: 80%;
  margin: 0 auto;
  border: 3px solid green;
  border-radius: 25px;
  padding-top: 30px;
  height: auto;
  margin-top: 30px;
  background-color: white;
  opacity: 0.9;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 0 0 10px white, 4px 4px 10px white, -4px -4px 10px white, 4px -4px 10px white, -4px 4px 10px white;
}

.text-center {
  text-align: center;
}

.custom-label {
  border: 1px solid gray;
  text-align: left;
  width: 50%;
  height: 35px;
  padding-left: 3px;
  padding-top: 7px;
  border-radius: 7px;
  background-color: white;
  display: block;
  margin-top: 10px;
}

button {
  cursor: pointer;
}

button:hover {
  background-color: darkgreen;
}

@media (max-width: 768px) {
  .container {
    width: 95%;
    margin-top: 15px;
    padding: 10px;
  }

  .custom-label {
    font-size: 14px;
    padding-top: 5px;
    padding-left: 5px;
    height: auto;
  }

  button {
    width: 100%;
    margin-top: 10px;
    height: auto;
    padding: 10px;
  }

  input[type="text"] {
    width: 100%;
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .container {
    width: 100% !important;
    margin-top: 10px;
    padding: 5px;
    height: 650px !important;
  }

  .custom-label {
    font-size: 12px;
    padding-top: 3px;
    padding-left: 3px;
    height: auto;
  }

  button {
    width: 100%;
    margin-top: 10px;
    height: auto;
    padding: 8px;
  }

  input[type="text"] {
    width: 100%;
    margin-top: 10px;
  }

  .next {
    padding: 0px;
  }
  .btn{
    display: inline-block !important; 
    text-align: left !important;
  } 
}
@media screen and (max-width: 767px) {
  .container {
    width: 100% !important;
    margin-top: 10px;
    padding: 5px;
    height: 650px !important;
  }

  .custom-label {
    font-size: 12px;
    padding-top: 3px;
    padding-left: 3px;
    height: auto;
  }

  button {
    width: 100%;
    margin-top: 10px;
    height: auto;
    padding: 8px;
  }

  input[type="text"] {
    width: 100%;
    margin-top: 10px;
  }

  .next {
    padding: 0px;
  }
  .btn{
    display: inline-block !important; 
    text-align: left !important;
  } 

  
  }

  @media screen and (max-width: 767px) {
    .container {
      width: 100% !important;
      margin-top: 10px;
      padding: 5px;
      height: 650px !important;
    }
  }

  @media (max-width: 480px) {
  .container {
    width: 100% !important;
    margin-top: 10px;
    padding: 5px;
    height: 650px !important;
  }

  .custom-label {
    font-size: 12px;
    padding-top: 3px;
    padding-left: 3px;
    height: auto;
  }

  button {
    width: 100%;
    margin-top: 10px;
    height: 49px !important;
    padding: 8px;
  }

  input[type="text"] {
    width: 100%;
    margin-top: 10px;
  }

  .next {
    padding: 0px;
  }
  .btn{
    display: inline-block !important; 
    text-align: left !important;
  } 
 
}